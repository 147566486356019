import React, { Component } from "react";
import "./CustomAccordion.css";
import { withTranslation } from "react-i18next";

class CustomAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: null,
    };
  }

  handleAccordionClick = (index) => {
    this.setState({
      activeIndex: index === this.state.activeIndex ? null : index,
    });
  };

  render() {
    const { t } = this.props;

    const { activeIndex } = this.state;

    const accordionItems = [
      {
        backgroundUrl: "/assets/images/ProjePages/kent-parki-projesi/16.jpg",
        title: `${t("furniture")}`,
        subDetail: [
          {
            id: 1,
            title: `${t("furniture")}`,
          },
          {
            id: 2,
            title: `${t("gardenfurniture")}`,
          },
          {
            id: 3,
            title: `${t("streetfurniture")}`,
          },
          {
            id: 4,
            title: `${t("beachfurniture")}`,
          },
        ],
      },
      {
        backgroundUrl: "/assets/images/ProjePages/tetris-kent-parki/8.jpg",
        title: `${t("woodenconstruction")}`,
        subDetail: [
          {
            id: 1,
            title: `${t("pergola")}`,
          },
          {
            id: 2,
            title: `${t("restaurant")}`,
          },
          {
            id: 3,
            title: `${t("beachbuildings")}`,
          },
          {
            id: 4,
            title: `${t("platforms")}`,
          },
          {
            id: 5,
            title: `${t("bridge")}`,
          },
        ],
      },
      {
        backgroundUrl: "/assets/images/ProjePages/toplu-konut-projesi/4.jpeg",
        title: `${t("floorandfacade")}`,
        subDetail: [
          {
            id: 1,
            title: `${t("facadecladding")}`,
          },
          {
            id: 2,
            title: `${t("hallowfacadecladding")}`,
          },
          {
            id: 3,
            title: `${t("gridcoverings")}`,
          },
          {
            id: 4,
            title: `${t("deckcoatings")}`,
          },
          {
            id: 5,
            title: `${t("beachbuildings")}`,
          },
        ],
      },
      {
        backgroundUrl: "/assets/images/bg/bg.png",
        title: `${t("woodenhouse")}`,
        subDetail: [
          {
            id: 1,
            title: `${t("woodenhouse")}`,
          },
          {
            id: 2,
            title: `${t("singlehouse")}`,
          },
          {
            id: 3,
            title: `${t("doublehouse")}`,
          },
          {
            id: 4,
            title: `${t("tinyhouse")}`,
          },
          {
            id: 5,
            title: `${t("Bungalows")}`,
          },
          {
            id: 6,
            title: `${t("trianglehouses")}`,
          },
        ],
      },
      {
        backgroundUrl: "/assets/images/ProjePages/kent-parki-projesi/19.jpg",
        title: `${t("graphicdesign")}`,
        subDetail: [
          {
            id: 1,
            title: `${t("graphicdrawing")}`,
          },
          {
            id: 2,
            title: `${t("design")}`,
          },
        ],
      },
    ];

    return (
      <div className="site-outer mobil">
        <div className="site-inner">
          <section className="container-fluid">
            <div className="row" style={{ width: "100%" }}>
              <ul
                className="accordion-group"
                id="accordion"
                // style={{
                //   display: "flex",
                //   justifyContent: "center",
                // }}
              >
                {accordionItems.map((item, index) => (
                  <li
                    key={index}
                    className={`${activeIndex === index ? "out" : ""}`}
                    style={{
                      backgroundImage: `url('${item.backgroundUrl}')`,
                    }}
                    // onClick={() => this.handleAccordionClick(index)}
                  >
                    <div className="accordion-overlay"></div>
                    <div className="row" style={{ width: "100%" }}>
                      <div className="container">
                        <div className="col-sm-12">
                          <h3 style={{ color: "white", width: "100%" }}>
                            {item.title}
                          </h3>
                        </div>
                        {/*
<div className="col-sm-12">
<section className="">
  <article>
    <ul>
      {item.subDetail.map((sub, i) => (
        <div
          key={i}
          // onClick={() =>
          //   (window.location.href = sub.link)
          // }
        >
          <a
            href={sub.link}
            style={{
              textDecoration: "none",
              width: "100%",
            }}
          > 
          <p style={{ marginBlock: "15px" }}>
            {sub.title}
          </p>
          </a> *
        </div>
      ))}
    </ul>
  </article>
</section>
</div>
  */}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomAccordion);
