import React, { Component } from "react";
import Slider from "react-slick";
import { slideSlick } from "../../page-demo/script";
import { withTranslation } from "react-i18next";

const SlideList = [
  {
    textPosition: "text-center",
    bgImage: "bg_image--15",
  },
  {
    textPosition: "text-center",
    bgImage: "bg_image--14",
  },
  {
    textPosition: "text-center",
    bgImage: "bg_image--12",
  },
  {
    textPosition: "text-center",
    bgImage: "bg_image--13",
  },
];
const SlideListMobil = [
  {
    textPosition: "text-center",
    bgImage: "bg_image--16",
  },
  {
    textPosition: "text-center",
    bgImage: "bg_image--14",
  },
  {
    textPosition: "text-center",
    bgImage: "bg_image--12",
  },
  {
    textPosition: "text-center",
    bgImage: "bg_image--13",
  },
];
class SliderTwo extends Component {
  render() {
    const { t } = this.props;

    const handleScrollDown = () => {
      window.scrollTo({
        top: window.scrollY + 600,
        behavior: "smooth",
      });
    };
    return (
      <>
        <div className="slider-activation desktop-version">
          <Slider className=" " {...slideSlick}>
            {SlideList.map((value, index) => (
              <div
                className={`slide slide-style-2 fullscreen d-flex align-items-end justify-content-end bg_image  ${value.bgImage}`}
                key={index}
              >
                <div className="container ">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={`inner ${value.textPosition}`}>
                        {/* SLİDER BİZ KİMİZ BUTONU START */}
                        <div
                          className="slide-btn mobil"
                          // onClick={handleScrollDown}
                        >
                          <a
                            className="rn-button-style--2 btn-primary-color"
                            style={{
                              padding: "10px 50px",
                              fontSize: "50px",
                              letterSpacing: 1,
                              fontWeight: 500,
                              color: "#fff",
                              WebkitTextStroke: "0.3px #000",
                            }}
                            href="/portfolio"
                          >
                            {t("references")}
                          </a>
                        </div>
                        <div
                          className="slide-btn mobilto mobilxl"
                          // onClick={handleScrollDown}
                        >
                          <a
                            className="rn-button-style--2 btn-primary-color"
                            style={{
                              padding: "10px 30px",
                              fontSize: "30px",
                              letterSpacing: 1,
                              fontWeight: 500,
                              color: "#fff",
                              backgroundColor: "#000000c0",
                              borderColor: "#000000c0",
                              WebkitTextStroke: "0.3px #000",
                            }}
                            href="/portfolio"
                          >
                            {t("references")}
                          </a>
                        </div>
                        {/* SLİDER BİZ KİMİZ BUTONU END */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="slider-activation mobile-version">
          <Slider className=" " {...slideSlick}>
            {SlideListMobil.map((value, index) => (
              <div
                className={`slide slide-style-2 fullscreen d-flex align-items-end justify-content-end bg_image  ${value.bgImage}`}
                key={index}
              >
                <div className="container ">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={`inner ${value.textPosition}`}>
                        {/* SLİDER BİZ KİMİZ BUTONU START */}
                        <div
                          className="slide-btn mobil"
                          // onClick={handleScrollDown}
                        >
                          <a
                            className="rn-button-style--2 btn-primary-color"
                            style={{
                              padding: "10px 50px",
                              fontSize: "50px",
                              letterSpacing: 1,
                              fontWeight: 500,
                              color: "#fff",
                              WebkitTextStroke: "0.3px #000",
                            }}
                            href="/portfolio"
                          >
                            {t("references")}
                          </a>
                        </div>
                        <div
                          className="slide-btn mobilto mobilxl"
                          // onClick={handleScrollDown}
                        >
                          <a
                            className="rn-button-style--2 btn-primary-color"
                            style={{
                              padding: "10px 30px",
                              fontSize: "30px",
                              letterSpacing: 1,
                              fontWeight: 500,
                              color: "#fff",
                              backgroundColor: "#000000c0",
                              borderColor: "#000000c0",
                              WebkitTextStroke: "0.3px #000",
                            }}
                            href="/portfolio"
                          >
                            {t("references")}
                          </a>
                        </div>
                        {/* SLİDER BİZ KİMİZ BUTONU END */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </>
    );
  }
}
export default withTranslation()(SliderTwo);
