import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import {
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
  FaUserFriends,
  FaYoutube,
  FaPinterest,
} from "react-icons/fa";
import { withTranslation } from "react-i18next";
import StickyHeader from "../component/header/StickyHeader";

const SocialShare = [
  {
    Social: <FaFacebookF />,
    link: "https://www.facebook.com/esra.altan.1650?locale=tr_TR",
  },
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/in/esra-altan-80566325b/",
  },
  {
    Social: <FaInstagram />,
    link: "https://www.instagram.com/esra.altannn/",
  },
];
class Contact extends Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11,
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Contact" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        <StickyHeader />
        <div
          className="rn-page-title-area pt--40 pb--60 bg_image bg_image--35"
          data-black-overlay="6"
        ></div>
        <div className="rn-contact-top-area ptb--60 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1
                  className="textMobil"
                  style={{
                    textAlign: "center",
                    paddingBottom: "30px",
                    fontWeight: 300,
                    fontSize: "50px",
                  }}
                >
                  {t("contactus")}
                </h1>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiHeadphones />
                  </div>
                  <div className="inner">
                    <h4 className="title"> {t("phone")}</h4>
                    <p>
                      <a href="tel:+905075779755">+90 507 577 97 55</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt_mobile--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title"> {t("email")}</h4>
                    <p>
                      <a href="mailto:info@esraaltan.com.tr">
                        info@esraaltan.com.tr
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt_md--50 mt_sm--50  ">
                <div className="rn-address ">
                  <div className="icon ">
                    <FaUserFriends />
                  </div>
                  <div className="inner">
                    <h4 className="title"> {t("socialmedia")}</h4>

                    <div className="container">
                      <ul className="social-share rn-lg-size row liststyle ">
                        {SocialShare.map((val, i) => (
                          <li key={i} className="p-2">
                            <a href={`${val.link}`}> {val.Social} </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt_md--50 mt_sm--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">{t("woodfactory")}</h4>
                    <p>{t("furniturefactory")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>

        <Footer />
      </React.Fragment>
    );
  }
}
export default withTranslation()(Contact);
