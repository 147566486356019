// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";
// Create Import File
import "./index.scss";
import PageScrollTop from "./component/PageScrollTop";
// Home layout
import MainDemo from "./home/MainDemo";
// Dark Home Layout
// Element Layout
import About from "./elements/About";

import Contact from "./elements/Contact";
import error404 from "./elements/error404";
import Portfolio from "./elements/Portfolio";

// Blocks Layout

import VideoPopup from "./blocks/VideoPopup";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

// PROJE PAGES

import ProjectDetail from "./proje-pages/ProjectDetail";
import LuxuryHouse from "./proje-pages/LuxuryHouse";
import StandarHouse from "./proje-pages/StandartHouse";
//Translate
import { I18nextProvider } from "react-i18next";
import i18n from "./home/i18n";
//Category Pages
import Mobilya from "./category/Mobilya";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <I18nextProvider i18n={i18n}>
            <Switch>
              {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} /> */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/`}
                component={MainDemo}
              />
              {/* Category Pages START */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/mobilya`}
                component={Mobilya}
              />
              {/* Category Pages END */}

              {/* PROJE PAGES*/}

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/tetris-kent-parki`}
                component={LuxuryHouse}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/toplu-konut-projesi`}
                component={StandarHouse}
              />

              {/* Element Layot */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/about`}
                component={About}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/kent-parki-projesi`}
                component={ProjectDetail}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/contact`}
                component={Contact}
              />

              {/* Blocks Elements  */}

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/portfolio`}
                component={Portfolio}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/video-popup`}
                component={VideoPopup}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/404`}
                component={error404}
              />
              <Route component={error404} />
            </Switch>
          </I18nextProvider>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
