import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import "../elements/kurumsal.css";
import { withTranslation } from "react-i18next";
import StickyHeader from "../component/header/StickyHeader";

class Service extends Component {
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Hakkımda" />
        <Header />
        <StickyHeader />

        {/* Start Breadcrump Area */}
        <Breadcrumb title={"Hakkımda"} />
        {/* End Breadcrump Area */}
        <div className="about-area pb--50 pt--50   ">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-12">
                  <h1
                    className="textMobil"
                    style={{
                      textAlign: "center",

                      fontWeight: 300,
                      fontSize: "50px",
                    }}
                  >
                    Esra Altan
                  </h1>
                  <h1
                    className="textMobil"
                    style={{
                      textAlign: "center",
                      paddingBottom: "30px",
                      fontWeight: 300,
                      fontSize: "50px",
                    }}
                  >
                    {t("lanspace")}
                  </h1>
                </div>

                <div className="col-lg-12">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h3
                        className="title subtitle"
                        style={{ fontSize: "20px", fontWeight: 500 }}
                      >
                        {t("aboutus1")}
                      </h3>
                      <p className="description" style={{ fontSize: "17px" }}>
                        {t("aboutus2")}
                      </p>
                      <p className="description" style={{ fontSize: "17px" }}>
                        {t("aboutus4")}
                      </p>
                      <p className="description" style={{ fontSize: "17px" }}>
                        {t("aboutus6")}
                      </p>
                      <p className="description" style={{ fontSize: "17px" }}>
                        {t("aboutus8")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <br />
                  <br />
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h3
                        className="title subtitle"
                        style={{ fontSize: "20px", fontWeight: 500 }}
                      >
                        {t("aboutus3")}
                      </h3>
                      <p className="description" style={{ fontSize: "17px" }}>
                        {t("aboutus9")}
                      </p>
                      <p className="description" style={{ fontSize: "17px" }}>
                        {t("aboutus10")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <br />
                  <br />
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h3
                        className="title subtitle"
                        style={{ fontSize: "20px", fontWeight: 500 }}
                      >
                        {t("aboutus5")}
                      </h3>
                      <p className="description" style={{ fontSize: "17px" }}>
                        {t("about11")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Start Service Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default withTranslation()(Service);
