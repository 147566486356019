import React, { Component } from "react";

class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <ul className="brand-style-2">
          <li>
            <img
              src="\assets\images\ProjePages\logolar\habitat.png"
              alt="Logo Images"
            />
          </li>
          <li>
            <img
              src="\assets\images\ProjePages\logolar\soffa.jpg"
              alt="Logo Images"
            />
          </li>
          <li>
            <img
              src="\assets\images\ProjePages\logolar\omu.png"
              alt="Logo Images"
            />
          </li>
          <li>
            <img
              src="\assets\images\ProjePages\logolar\odu.png"
              alt="Logo Images"
            />
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
export default BrandTwo;
