import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // react için gerekli olan i18next'in react entegrasyonunu etkinleştirin
  .init({
    resources: {
      en: {
        translation: {
          aboutmainpage:
            "Hello! I am Esra ALTAN, born in 1997, a passionate and innovative landscape architect. I graduated from Ondokuz Mayıs University with a degree in Landscape and Ornamental Plants...",
          allrights: "All rights reserved.",
          contact: "Contact",
          detail: "Detail",
          institutional: "About Me",
          whoweare: "CV",
          home: "Home",
          references: "My Portfolio",
          referencestextone: "Projects where dreams are possible",
          furniture: "Project Drawing",
          floorandfacade: "Irrigation Systems",
          woodenconstruction: "Yapısal Tasarım",
          woodenhouse: "2D Project Drawing and Design",
          graphicdesign: "3D Project drawing and design",
          aboutus1: "About Me",
          aboutus2:
            "Hello! I am Esra ALTAN, born in 1997, a passionate and innovative landscape architect. I graduated from Ondokuz Mayıs University with a degree in Landscape and Ornamental Plants and then continued my undergraduate education in the Landscape Architecture Department at Ordu University through the Vertical Transfer Exam (DGS). I graduated as the top student in my department in 2022 and have eagerly embarked on my career.",
          aboutus3: "My Vision",
          aboutus4:
            "During my university years, I had the opportunity to apply the theoretical knowledge I acquired in practice. By combining a modern design approach with respect for nature and environmental sustainability, I aim to create designs that are in harmony with nature and meet user needs in every project.",
          aboutus5: "My Mission",
          aboutus6:
            "My experiences in projects of different scales, my creative thinking ability, and my attention to detail allow me to bring an innovative and customer-focused approach to each project. Understanding my clients' needs and expectations and turning their dreams into reality is a great source of happiness for me.",
          aboutus7: "Our Goals and Future Goals",
          aboutus8:
            "At the beginning of my career, I focus on continuously improving myself by working on freelance projects and with various firms. I aim to achieve effective results in projects with innovative approaches and teamwork. I closely follow innovations in landscape architecture and continue to learn and develop every day.I aim to sign projects that will increase people's quality of life while preserving the beauty of nature. By working with me, you can turn your dream landscape projects into reality.",
          aboutus9:
            "By combining the natural and built environment in a sustainable and aesthetically rich way, I aim to create inspiring and innovative landscapes where people can live in harmony with nature. In each project, I strive to design spaces that meet the needs of modern life while preserving the beauty and resources of nature.",
          aboutus10:
            "My goal is to provide landscape solutions that encourage people to reconnect with nature, raise environmental awareness, and leave a livable, healthy environment for future generations. I aim to create aesthetically and functionally superior living spaces that are in harmony with nature through creative and sustainable designs.",
          about11:
            "As a new generation landscape architect, I aim to develop projects that prioritize environmental sustainability, preserve the beauty of nature, and make urban life more livable. By offering creative solutions to turn my clients' dreams into reality, I strive to meet their needs and expectations in the best possible way. With each project, I aim to contribute to the conservation of natural resources and the enhancement of the quality of community life.",
          phone: "Phone",
          email: "E-mail",
          socialmedia: "Social Media",
          office: "Office",
          woodfactory: "Adress",
          furniturefactory: "Antalya / Turkey",
          contactus: "Contact Me",
          allprojects: "All Projects",
          pages: "Pages",
          woodenconstruction: "Wooden Construction",
          floorandfacadecoverings: "Floor and Facade Coverings",
          woodenhouse: "2D Project drawing and design",
          details: "Details",
          category: "Category",
          portfolyo: "My Porfolio",
          banister: "Banister",
          lanspace: "Lanspace Architect",
          facadecladding: "Facade Cladding",
          hallowfacadecladding: "Hollow Facade Cladding",
          gridcoverings: "Grid Coverings",
          deckcoatings: "Deck Coatings",

          beachbuildings: "Beach Buildings",
          bridge:
            "The project area has an average surface area of 20,000 m². There are specific requirements set by the metropolitan municipality for the Kant Park project, including a bicycle path, parking lot, and a kiosk / restroom. ",
          company:
            "The project area consists of two parts according to AutoCAD measurements: 17,200 m² and 3,280 m². In addition to the desired features, the area includes a Park Square, covered and open seating units, a basketball court, a tennis court, a fitness area, two different scent gardens, and a chess playing area.",
          othercompany: "Other Companies",
          enddate: "End Date",
          totalarea: "Theme park design project",
          place:
            "The work area covers 37,600 m². It includes 3 buildings and 5 private villas. There is a 5-meter slope between each level. The area has 84 apartments. There are 16,000 m² of hard surfaces and 26,000 m² of green space.",
          workdone: "Work Done",
          gardenfurniture: "Garden Furnitures",
          streetfurniture: "Street Furniture",
          beachfurniture: "Beach Furniture",
          platforms: "Platforms",
          restaurant: "Restorants",
          singlehouse: "Single Storey Wooden Buildings",
          doublehouse: "Double Storey Wooden Buildings",
          tinyhouse: "Tiny Houses",
          Bungalows: "Bungalows",
          trianglehouses: "Triangle Houses",
          graphicdrawing: "Graphic Drawing",
          design: "Design",
        },
      },
      tr: {
        translation: {
          aboutmainpage:
            "Merhaba! Ben 1997 doğumlu Esra ALTAN peyzaj mimarlığı alanında tutkulu ve yenilikçi bir peyzaj mimarıyım. Ondokuz Mayıs Üniversitesi Peyzaj ve Süs Bitkileri Bölümü'nden mezun oldum ve ardından DGS (Dikey Geçiş Sınavı) ile tamamladığım lisans eğitimini Ordu Üniversitesi'nde Peyzaj Mimarlığı Bölümü'nde sürdürdüm. 2022 yılında bölüm birincisi olarak mezun oldum... ",
          institutional: "Hakkımda",
          whoweare: "CV",
          home: "Anasayfa",
          references: "Portfolyo",
          contact: "İletişim",
          allrights: "Tüm hakları saklıdır.",
          referencestextone: "  Hayallerin mümkün olduğu projeler",
          detail: "Detay",
          furniture: "Bitkisel Tasarım",
          woodenconstruction: "AHŞAP KONSTRÜKSİYON",
          floorandfacade: "Sulama Sistemleri",
          woodenhouse: "Ahşap Ev",
          graphicdesign: "3D Proje Çizim ve Tasarımı",
          aboutus1: "Hakkımda",
          aboutus2:
            "Merhaba! Ben 1997 doğumlu Esra ALTAN peyzaj mimarlığı alanında tutkulu ve yenilikçi bir peyzaj mimarıyım. Ondokuz Mayıs Üniversitesi Peyzaj ve Süs Bitkileri Bölümü'nden mezun oldum ve ardından DGS (Dikey Geçiş Sınavı) ile tamamladığım lisans eğitimini Ordu Üniversitesi'nde Peyzaj Mimarlığı Bölümü'nde sürdürdüm. 2022 yılında bölüm birincisi olarak mezun oldum ve kariyerime heyecanla adım attım.",
          aboutus3: "Vizyonum",
          aboutus4:
            "Üniversite yıllarımda aldığım teorik bilgileri şimdi pratikte uygulama fırsatı buluyorum. Modern tasarım anlayışını, doğaya saygı ve çevresel sürdürülebilirliği bir araya getirerek, her projede doğayla uyumlu ve kullanıcı ihtiyaçlarına cevap veren tasarımlar oluşturmayı hedefliyorum.Farklı ölçeklerdeki projelerde edindiğim deneyimler, yaratıcı düşünme yeteneğim ve detaylara verdiğim önemle, her projeye yenilikçi ve müşteri odaklı bir yaklaşım gösteriyorum. Müşterilerimin ihtiyaçlarını ve beklentilerini anlamak ve hayallerini gerçeğe dönüştürmek benim için büyük bir mutluluk kaynağıdır.",
          aboutus5: "Misyonum",
          aboutus6:
            "Kariyerimin başlangıcında hem serbest projelerde hem de çeşitli firmalarda çalışarak, kendimi sürekli geliştirmeye odaklanıyorum. Yenilikçi yaklaşımlar ve takım çalışması ile projelerde etkili sonuçlar elde etmeyi amaçlıyorum. Peyzaj mimarlığındaki yenilikleri yakından takip ediyor ve her geçen gün öğrenmeye ve gelişmeye devam ediyorum.",
          aboutus7: " Amaçlarımız & Gelecek Hedeflerimiz",
          aboutus8:
            "Doğanın güzelliklerini koruyarak, insanların yaşam kalitesini artıracak projelere imza atmayı hedefliyorum. Siz de benimle birlikte çalışarak, hayalinizdeki peyzaj projelerini gerçeğe dönüştürebilirsiniz.",
          aboutus9:
            "Doğal ve yapısal çevreyi sürdürülebilir ve estetik açıdan zengin bir şekilde birleştirerek, insanların doğa ile uyum içinde yaşayabileceği, ilham verici ve yenilikçi peyzajlar oluşturmayı amaçlıyorum. Her projede, doğanın güzelliklerini ve kaynaklarını koruyarak, modern yaşamın ihtiyaçlarını karşılayan alanlar tasarlamak istiyorum.",
          aboutus10:
            "Amacım, insanları doğayla yeniden bağlantı kurmaya teşvik eden, çevre bilincini artıran ve gelecek nesillere yaşanabilir, sağlıklı bir çevre bırakacak peyzaj çözümleri sunmak. Yaratıcı ve sürdürülebilir tasarımlarla hem estetik hem de fonksiyonel açıdan üstün, doğayla uyumlu yaşam alanları yaratmayı hedefliyorum.",
          about11:
            "Yeni nesil peyzaj mimarı olarak, çevresel sürdürülebilirliği ön planda tutarak, doğanın güzelliklerini koruyan ve şehir yaşamını daha yaşanabilir kılan projeler geliştirmeyi amaçlıyorum. Müşterilerimin hayallerini gerçeğe dönüştürmek için yaratıcı çözümler sunarak, onların ihtiyaçlarına ve beklentilerine en iyi şekilde cevap vermek için çalışıyorum. Her proje ile, doğal kaynakların korunmasına ve toplumsal yaşam kalitesinin artırılmasına katkıda bulunmayı hedefliyorum.",
          phone: "Telefon",
          email: "E-mail",
          socialmedia: "Sosyal Medya",
          office: "Ofis",
          woodfactory: "Adres",
          furniturefactory: "Antalya / Türkiye",
          contactus: "İletişim",
          allprojects: "Tüm Projeler",
          pages: "Sayfalar",
          woodenconstruction: "Yapısal Tasarım",
          floorandfacadecoverings: "Zemin ve Cephe Kaplamaları",
          woodenhouse: "2D Proje çizim ve tasarımı",
          details: "Detaylar",
          category: "Kategori",
          portfolyo: "Portfolyom",
          banister: "Korkuluk",
          lanspace: "Peyzaj Mimarı",
          facadecladding: "Cephe Kaplama",
          hallowfacadecladding: "Boşluklu Cephe Kaplama",
          gridcoverings: "Grid Kaplamaları",
          deckcoatings: "Deck Kaplamalar",
          beachbuildings: "Sahil Yapıları",
          bridge:
            " Proje alanı ortalama 20.000 m2 yüz ölçümüne sahiptir. Büyükşehir belediyesinin kant parkı projesi kapsamında istediği belirli nitelikler mevcuttur. Bunlar; Bisiklet yolu, Otopark, Büfe / WC dir.",
          company:
            "Proje alanı AutoCAD ölçümlerine göre 17.200 m2 ve 3.280 m2 olmak üzere iki farklı parçadan oluşmaktadır. Alanda istenilen niteliklerin yanı sıra Park Meydanı, Üstü kapalı ve üstü açık oturma birimleri, Basketbol sahası ve Tenis sahası, Fitness spor alanı, 2 Farklı koku bahçesi, Satranç oyun alanı mevcuttur.",
          othercompany: "Diğer Firmalar",
          enddate: "Bitiş Tarihi",
          totalarea: " Tema parkı tasarım projesi",
          place:
            "Çalışma alanı 37.600 m’ büyüklüğündedir.Alanda 3 adet bina 5 adet özel villa bulunmaktadır.Alanda her tesviye arasında 5 metrelik eğim mevcuttur.84 adet daire mevcuttur.Alanda 16.000 m² sert zemin 26.000  m² yeşil alan bulunmaktadır.",
          workdone: "Yapılan İş",
          gardenfurniture: "Bahçe Mobilyaları",
          streetfurniture: "Kent Mobilyaları",
          beachfurniture: "Sahil  Mobilyaları",
          platforms: "Platformlar",
          restaurant: "Restorantlar",
          singlehouse: "Tek Katlı Ahşap Yapılar",
          doublehouse: "İki Katlı Ahşap Yapılar",
          tinyhouse: "Tiny House",
          Bungalows: "Bungalovlar",
          trianglehouses: "Üçgen Evler",
          graphicdrawing: "Grafik Çizim",
          design: "Tasarım",
        },
      },
    },
    lng: "tr",
    fallbackLng: "tr",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
