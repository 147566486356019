import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";
import trFlag from "../../../public/assets/images/icons/tr.webp";
import engFlag from "../../../public/assets/images/icons/eng.png";
import { withTranslation } from "react-i18next";

class StickyHeader extends Component {
  changeLanguage = (language) => {
    const { i18n } = this.props;
    i18n.changeLanguage(language);
  };

  constructor(props) {
    super(props);
    this.state = {
      isSticky: false,
      isVisible: false,
    };
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    if (window.scrollY > 200) {
      this.setState({ isSticky: true, isVisible: true });
    } else {
      this.setState({ isSticky: false, isVisible: false });
    }
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  render() {
    const { t } = this.props;
    const { isSticky, isVisible } = this.state;

    const headerStyle = {
      position: isSticky && isVisible ? "fixed" : "absolute",
      top: isSticky && isVisible ? 0 : "-100px",
      left: 0,
      width: "100%",
      zIndex: 99, // Z-index'i artırarak transparent header'ın önüne çıkmasını sağlıyoruz
      backgroundColor: isSticky && isVisible ? "white" : "transparent",
      boxShadow:
        isSticky && isVisible ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "none",
      transition: "all 0.3s ease",
      transform: isSticky && isVisible ? "translateY(0)" : "translateY(-100%)",
      opacity: isSticky && isVisible ? 1 : 0,
    };

    return (
      <header
        className="header-area formobile-menu header--fixed"
        style={headerStyle}
      >
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <a href="/">
                <img
                  className="logo-1"
                  src="/assets/images/logo/esraaltan-logo.png"
                  alt="esra-altan-logo"
                  height={"75px"}
                />
                <img
                  className="logo-2"
                  src="/assets/images/logo/esraaltan-logo.png"
                  alt="esra-altan-logo"
                />
              </a>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li>
                  <Link to="/">
                    <p style={{ fontSize: "18px", color: "#ad926d" }}>
                      {" "}
                      {t("home")}
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/about">
                    <p style={{ fontSize: "18px", color: "#ad926d" }}>
                      {" "}
                      {t("institutional")}{" "}
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/portfolio">
                    <p style={{ fontSize: "18px", color: "#ad926d" }}>
                      {" "}
                      {t("references")}
                    </p>
                  </Link>
                </li>

                <li>
                  <Link to="/contact">
                    <p style={{ fontSize: "18px", color: "#ad926d" }}>
                      {" "}
                      {t("contact")}{" "}
                    </p>
                  </Link>
                </li>
                <li>
                  <div style={{ paddingTop: "15px" }}>
                    <button
                      onClick={() => this.changeLanguage("tr")}
                      style={{
                        padding: "0px",
                      }}
                    >
                      <img
                        src={trFlag}
                        alt=""
                        style={{ width: "40px", height: "30px" }}
                      />
                    </button>
                  </div>
                </li>
                <li>
                  <div style={{ paddingTop: "15px" }}>
                    <button
                      onClick={() => this.changeLanguage("en")}
                      style={{ padding: "0px" }}
                    >
                      <img
                        src={engFlag}
                        alt=""
                        style={{ width: "40px", height: "30px" }}
                      />
                    </button>
                  </div>
                </li>
              </ul>
            </nav>

            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
                style={{
                  backgroundColor: "#000000c0",
                  paddingInline: 15,
                  borderRadius: "20px",
                }}
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default withTranslation()(StickyHeader);
