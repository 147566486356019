import React, { Component } from "react";
import "./CustomAccordionMobil.css";
import { Accordion, Card } from "react-bootstrap";
import { withTranslation } from "react-i18next";

class CustomAccordionMobil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: null,
    };
  }

  handleAccordionClick = (index) => {
    this.setState({
      activeIndex: index === this.state.activeIndex ? null : index,
    });
  };

  render() {
    const { t } = this.props;

    const { activeIndex } = this.state;
    const accordionItems = [
      {
        backgroundUrl:
          "https://images.unsplash.com/photo-1556912172-45b7abe8b7e1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        title: `${t("furniture")}`,
        subDetail: [
          {
            id: 1,
            title: `${t("furniture")}`,
          },
          {
            id: 2,
            title: `${t("gardenfurniture")}`,
          },
          {
            id: 3,
            title: `${t("streetfurniture")}`,
          },
          {
            id: 4,
            title: `${t("beachfurniture")}`,
          },
        ],
      },
      {
        backgroundUrl:
          "https://images.unsplash.com/photo-1484154218962-a197022b5858?ixlib=rb-1.2.1&auto=format&fit=crop&w=1953&q=80",
        title: `${t("woodenconstruction")}`,
        subDetail: [
          {
            id: 1,
            title: `${t("pergola")}`,
          },
          {
            id: 2,
            title: `${t("restaurant")}`,
          },
          {
            id: 3,
            title: `${t("beachbuildings")}`,
          },
          {
            id: 4,
            title: `${t("platforms")}`,
          },
          {
            id: 5,
            title: `${t("bridge")}`,
          },
        ],
      },
      {
        backgroundUrl:
          "https://images.unsplash.com/photo-1507089947368-19c1da9775ae?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1955&q=80",
        title: `${t("floorandfacade")}`,
        subDetail: [
          {
            id: 1,
            title: `${t("facadecladding")}`,
          },
          {
            id: 2,
            title: `${t("hallowfacadecladding")}`,
          },
          {
            id: 3,
            title: `${t("gridcoverings")}`,
          },
          {
            id: 4,
            title: `${t("deckcoatings")}`,
          },
          {
            id: 5,
            title: `${t("beachbuildings")}`,
          },
        ],
      },
      {
        backgroundUrl:
          "https://images.unsplash.com/photo-1495433324511-bf8e92934d90?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        title: `${t("woodenhouse")}`,
        subDetail: [
          {
            id: 1,
            title: `${t("woodenhouse")}`,
          },
          {
            id: 2,
            title: `${t("singlehouse")}`,
          },
          {
            id: 3,
            title: `${t("doublehouse")}`,
          },
          {
            id: 4,
            title: `${t("tinyhouse")}`,
          },
          {
            id: 5,
            title: `${t("Bungalows")}`,
          },
          {
            id: 6,
            title: `${t("trianglehouses")}`,
          },
        ],
      },
      {
        backgroundUrl:
          "https://images.unsplash.com/photo-1556912172-45b7abe8b7e1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        title: `${t("graphicdesign")}`,
        subDetail: [
          {
            id: 1,
            title: `${t("graphicdrawing")}`,
          },
          {
            id: 2,
            title: `${t("design")}`,
          },
        ],
      },
    ];

    return (
      <div className=" custommobil custommobilsecond" style={{ padding: 0 }}>
        {accordionItems.map((item, index) => (
          <Accordion defaultActiveKey={item.id}>
            <Card>
              <div
                style={{
                  backgroundColor: `${item.bgColor}`,
                  borderColor: "red",
                }}
              >
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  <h1
                    style={{
                      fontSize: "22px",
                      fontWeight: 300,
                      letterSpacing: 2,
                      color: `${item.titleColor}`,
                      textAlign: "center",
                    }}
                  >
                    {item.title}
                  </h1>
                </Accordion.Toggle>
                <div
                  style={{
                    backgroundColor: "white",
                    backgroundImage: `url("https://images.unsplash.com/photo-1507089947368-19c1da9775ae?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1955&q=80")`,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                ></div>
              </div>
            </Card>
          </Accordion>
        ))}
      </div>
    );
  }
}

export default withTranslation()(CustomAccordionMobil);
